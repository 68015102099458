namespace $ {
	export class $pairme extends $mol_view {
		
		/**
		 * ```tree
		 * title @ \Pair Me!
		 * ```
		 */
		title() {
			return this.$.$mol_locale.text( '$pairme_title' )
		}
		
		/**
		 * ```tree
		 * sub /
		 * 	<= Auth
		 * 	<= Main
		 * ```
		 */
		sub() {
			return [
				this.Auth(),
				this.Main()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Auth $pairme_auth
		 * ```
		 */
		@ $mol_mem
		Auth() {
			const obj = new this.$.$pairme_auth()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Main $pairme_main
		 * ```
		 */
		@ $mol_mem
		Main() {
			const obj = new this.$.$pairme_main()
			
			return obj
		}
	}
	
}

