namespace $ {
	export class $pairme_main extends $mol_book2_catalog {
		
		/**
		 * ```tree
		 * menu_title @ \Pair Me!
		 * ```
		 */
		menu_title() {
			return this.$.$mol_locale.text( '$pairme_main_menu_title' )
		}
		
		/**
		 * ```tree
		 * plugins / <= Theme
		 * ```
		 */
		plugins() {
			return [
				this.Theme()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * menu_tools / <= Lights
		 * ```
		 */
		menu_tools() {
			return [
				this.Lights()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * spreads *
		 * 	small <= Small
		 * 	medium <= Medium
		 * 	large <= Large
		 * ```
		 */
		spreads() {
			return {
				small: this.Small(),
				medium: this.Medium(),
				large: this.Large()
			} as Record< string, any >
		}
		
		/**
		 * ```tree
		 * menu_foot / <= Logout
		 * ```
		 */
		menu_foot() {
			return [
				this.Logout()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * Theme $mol_theme_auto
		 * ```
		 */
		@ $mol_mem
		Theme() {
			const obj = new this.$.$mol_theme_auto()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Lights $mol_lights_toggle
		 * ```
		 */
		@ $mol_mem
		Lights() {
			const obj = new this.$.$mol_lights_toggle()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Small $mol_page
		 * ```
		 */
		@ $mol_mem
		Small() {
			const obj = new this.$.$mol_page()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Medium $mol_page
		 * ```
		 */
		@ $mol_mem
		Medium() {
			const obj = new this.$.$mol_page()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Large $mol_page
		 * ```
		 */
		@ $mol_mem
		Large() {
			const obj = new this.$.$mol_page()
			
			return obj
		}
		
		/**
		 * ```tree
		 * logout? null
		 * ```
		 */
		@ $mol_mem
		logout(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Logout_icon $mol_icon_logout
		 * ```
		 */
		@ $mol_mem
		Logout_icon() {
			const obj = new this.$.$mol_icon_logout()
			
			return obj
		}
		
		/**
		 * ```tree
		 * Logout $mol_button_minor
		 * 	click? <= logout?
		 * 	sub / <= Logout_icon
		 * ```
		 */
		@ $mol_mem
		Logout() {
			const obj = new this.$.$mol_button_minor()
			
			obj.click = (next?: any) => this.logout()
			obj.sub = () => [
				this.Logout_icon()
			] as readonly any[]
			
			return obj
		}
	}
	
}

