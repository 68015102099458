namespace $.$$ {
	export class $pairme_auth extends $.$pairme_auth {
		
		login_submit( next?: any ) {
			console.log(next);
			this.$.$mol_state_local.value('auth', true);
		}

		login_demo( next?: any ) {
			this.login_submit( next );
		}
		
	}
}
