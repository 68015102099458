namespace $ {
	export class $pairme_auth extends $mol_page {
		
		/**
		 * ```tree
		 * title @ \Auth
		 * ```
		 */
		title() {
			return this.$.$mol_locale.text( '$pairme_auth_title' )
		}
		
		/**
		 * ```tree
		 * body / <= Login_form
		 * ```
		 */
		body() {
			return [
				this.Login_form()
			] as readonly any[]
		}
		
		/**
		 * ```tree
		 * email? \
		 * ```
		 */
		@ $mol_mem
		email(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Email_control $mol_string value? <=> email?
		 * ```
		 */
		@ $mol_mem
		Email_control() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.email(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Email_field $mol_form_field
		 * 	name @ \Login
		 * 	Content <= Email_control
		 * ```
		 */
		@ $mol_mem
		Email_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$pairme_auth_Email_field_name' )
			obj.Content = () => this.Email_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * password? \
		 * ```
		 */
		@ $mol_mem
		password(next?: any) {
			if ( next !== undefined ) return next as never
			return ""
		}
		
		/**
		 * ```tree
		 * Password_control $mol_string
		 * 	value? <=> password?
		 * 	type \password
		 * ```
		 */
		@ $mol_mem
		Password_control() {
			const obj = new this.$.$mol_string()
			
			obj.value = (next?: any) => this.password(next)
			obj.type = () => "password"
			
			return obj
		}
		
		/**
		 * ```tree
		 * Password_field $mol_form_field
		 * 	name @ \Password
		 * 	Content <= Password_control
		 * ```
		 */
		@ $mol_mem
		Password_field() {
			const obj = new this.$.$mol_form_field()
			
			obj.name = () => this.$.$mol_locale.text( '$pairme_auth_Password_field_name' )
			obj.Content = () => this.Password_control()
			
			return obj
		}
		
		/**
		 * ```tree
		 * login_submit? null
		 * ```
		 */
		@ $mol_mem
		login_submit(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Login $mol_button_major
		 * 	title @ \Logon
		 * 	click? <=> login_submit?
		 * ```
		 */
		@ $mol_mem
		Login() {
			const obj = new this.$.$mol_button_major()
			
			obj.title = () => this.$.$mol_locale.text( '$pairme_auth_Login_title' )
			obj.click = (next?: any) => this.login_submit(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * login_demo? null
		 * ```
		 */
		@ $mol_mem
		login_demo(next?: any) {
			if ( next !== undefined ) return next as never
			return null as any
		}
		
		/**
		 * ```tree
		 * Login_demo_user $mol_button_minor
		 * 	title @ \Demo
		 * 	click? <=> login_demo?
		 * ```
		 */
		@ $mol_mem
		Login_demo_user() {
			const obj = new this.$.$mol_button_minor()
			
			obj.title = () => this.$.$mol_locale.text( '$pairme_auth_Login_demo_user_title' )
			obj.click = (next?: any) => this.login_demo(next)
			
			return obj
		}
		
		/**
		 * ```tree
		 * Login_form $mol_form_draft
		 * 	form_fields /
		 * 		<= Email_field
		 * 		<= Password_field
		 * 	buttons /
		 * 		<= Login
		 * 		<= Login_demo_user
		 * ```
		 */
		@ $mol_mem
		Login_form() {
			const obj = new this.$.$mol_form_draft()
			
			obj.form_fields = () => [
				this.Email_field(),
				this.Password_field()
			] as readonly any[]
			obj.buttons = () => [
				this.Login(),
				this.Login_demo_user()
			] as readonly any[]
			
			return obj
		}
	}
	
}

